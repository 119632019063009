import { h, render, Fragment } from 'preact';
import classnames from 'classnames';

import { IconBitcoin } from './icons'

const plans = [
  require('../content/subscription-plans/1.json'),
  require('../content/subscription-plans/2.json'),
  require('../content/subscription-plans/3.json'),
];

const Plan = (props) => {
  return (
    <div class="col-md-4">
      <div class={classnames('price-box', { 'price-active': props.styleType === 'Red' })}>
        <div class="discount">
          {props.badge && (
            <p><span>{props.badge}</span></p> 
          )}
        </div>
        <div class="price-head">
            <h4>{props.title}</h4>
            <span>{props.isMostPopular && 'MOST POPULAR'}</span>
            <h3><IconBitcoin color={props.styleType === 'Red' ? 'red' : 'white'} /> {props.price}</h3>
            <p>{props.period}</p>
        </div>
        <div class="price-body">
          <ul>
            {props.points.map((point, idx) => (
              <li key={idx}>{point}</li>
            ))}
          </ul>
          <form action="https://www.coinpayments.net/index.php" method="post" dangerouslySetInnerHTML={{ 
            __html: `
              ${props.coinpaymentsCode}
              <button>
                join <br/> now
              </button>
            `
          }}/>
        </div>
      </div>
    </div>
  );
};

const Plans = () => {
  return (
    <>
      {plans.map((plan) => (
        <Plan
          key={plan.id}
          title={plan.title}
          price={plan.price}
          styleType={plan.styleType}
          period={plan.period}
          coinpaymentsCode={plan.coinpaymentsCode}
          isMostPopular={plan.isMostPopular}
          badge={plan.badge}
          points={plan.descriptionList}
        />
      ))}
    </>
  );
}

render(<Plans />, document.getElementById('plans'))