{
  "period": "1 YEAR",
  "badge": "",
  "price": " 0.015",
  "styleType": "Red",
  "coinpaymentsCode": "<input type=\"hidden\" name=\"cmd\" value=\"_pay_simple\">\n                                                    <input type=\"hidden\" name=\"reset\" value=\"1\">\n                                                    <input type=\"hidden\" name=\"merchant\" value=\"06ff5eb7e605900d7d0f6b893601c378\">\n                                                    <input type=\"hidden\" name=\"item_name\" value=\"Temple VIP (1 year)\">\n                                                    <input type=\"hidden\" name=\"currency\" value=\"BTC\">\n                                                    <input type=\"hidden\" name=\"amountf\" value=\"0.01500000\">\n                                                    <input type=\"hidden\" name=\"want_shipping\" value=\"0\">\n                                                    <input type=\"hidden\" name=\"success_url\" value=\"https://t.me/joinchat/eQUsfDszCncyZDE0\">",
  "isMostPopular": true,
  "title": "1 YEAR ACCESS",
  "descriptionList": [
    "Access to Richard Swing Channel",
    "Access to Richard Scalping Channel",
    "Access to Richard Altcoin Channel",
    "All signals can be fully automated",
    "Highly active support from me"
  ],
  "id": "2"
}