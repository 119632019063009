import { h } from "preact";

export function IconBitcoin({ color }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="30"
      fill="none"
      viewBox="0 0 20 30"
    >
      <path
        fill={color === 'red' ? '#FF3838' : '#fff'}
        d="M17.126 14.527a6.597 6.597 0 002.003-4.731c0-3.328-2.476-6.064-5.677-6.528V.333H9.667v2.84H5.882V.332H2.097v2.84H.204v3.784h1.893v15.14H.204v3.785h1.893v3.785h3.785v-3.785h3.785v3.785h3.785v-3.88c3.201-.465 5.677-3.201 5.677-6.529 0-1.854-.77-3.527-2.003-4.731zm-1.782-4.731a2.842 2.842 0 01-2.839 2.838H5.882V6.957h6.623a2.842 2.842 0 012.839 2.839zm-2.839 12.3H5.882V16.42h6.623a2.842 2.842 0 012.839 2.84 2.842 2.842 0 01-2.839 2.838z"
      ></path>
    </svg>
  );
}

